import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import HowToUse from '../../components/HowToUse';
import { genHowToUseBreadcrumbJsonLd } from '../../utils/jsonld';

const meta = {
  en: {
    title: 'Transform url to url with title in Evernote - EverTool',
    subtitle: 'Use EverTool to transform url to url with title in Evernote',
  },
  'zh-tw': {
    title: '在 Evernote 將連結轉換成連結及標題 - EverTool',
    subtitle: '用 EverTool 將連結轉換成連結及標題',
  },
  'zh-cn': {
    title: '在印象笔记将连结转换成连结及标题 - EverTool',
    subtitle: '用 EverTool 将连结转换成连结及标题',
  },
  ja: {
    title: 'Evernoteの中のタイトルとURLへのURL変換 - EverTool',
    subtitle: 'Evernoteの中のタイトルとURLへのURLを変換するために使用EverTool',
  },
};

class ThreeStepsToGetStartedPage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const config = {
      images: {
        mac: {
          intro1: [data.mac_step1],
          intro2: [data.mac_step2_1, data.mac_step2_2, data.mac_step2_3],
          intro3: [data.mac_step3],
        },
        win: {
          intro1: [data.win_step1],
          intro2: [data.win_step2_1, data.win_step2_2, data.win_step2_3],
          intro3: [data.win_step3],
        },
      },
      introId: {
        title: 'link.title',
        step1: 'link.step1',
        step2: 'link.step2',
        step3: 'link.step3',
      },
    };

    this.state = {
      config,
    };
  }

  render() {
    const { pageContext } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;
    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
          jsonld: genHowToUseBreadcrumbJsonLd(meta[locale].title, url, locale),
        }}
      >
        <div className="how-to-wrapper">
          <p className="center">Release in version 0.3.0 and later</p>
          <HowToUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
          />
        </div>
      </Layout>
    );
  }
}

export default ThreeStepsToGetStartedPage;

export const pageQuery = graphql`
  query HowToUrlQuery {
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
    mac_step1: file(relativePath: { eq: "how-to/link/mac/step1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_step2_1: file(relativePath: { eq: "how-to/link/mac/step2-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_step2_2: file(relativePath: { eq: "how-to/link/mac/step2-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_step2_3: file(relativePath: { eq: "how-to/link/mac/step2-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_step3: file(relativePath: { eq: "how-to/link/mac/step3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_step1: file(relativePath: { eq: "how-to/link/win/step1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_step2_1: file(relativePath: { eq: "how-to/link/win/step2-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_step2_2: file(relativePath: { eq: "how-to/link/win/step2-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_step2_3: file(relativePath: { eq: "how-to/link/win/step2-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_step3: file(relativePath: { eq: "how-to/link/win/step3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
